<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="companies"
      :loading="tableLoading"
      :options.sync="options"
      :server-items-length="totalCount"
      class="elevation-1"
      @dblclick:row="onClickedRow"
    >
      <template v-slot:top>
        <v-toolbar flat color="transparent">
          <v-layout style="max-width: 300px">
            <v-text-field
              ref="searchField"
              v-model="query"
              hide-details
              label="Recherche"
              clearable
              clear-icon="mdi-close"
              @click:clear="query = ''"
            />
          </v-layout>
          <v-spacer />
          <v-btn
            outlined small icon dark
            color="primary" class="mb-2 mr-2"
            @click="fetchData()"
          >
            <v-icon small>mdi-refresh</v-icon>
          </v-btn>
          <!-- <v-btn
            v-if="$store.state.auth.user.isAdmin"
            outlined small icon dark
            color="primary" class="mb-2 mr-2"
            @click="toggleSearch"
          >
            <v-icon small>mdi-magnify</v-icon>
          </v-btn> -->
          <v-btn
            v-if="$store.state.auth.user.isAdmin"
            dark color="accent" class="mb-2 ml-2"
            @click="$refs.editForm.open()"
          >
            <v-icon left>mdi-plus</v-icon>
            {{ $vuetify.breakpoint.smAndDown ? 'Ajouter' : 'Ajouter une entreprise' }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAtObject | formatDate }}
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ item.type | prettyCompanyType }}
      </template>
      <template v-slot:[`item.address`]="{ item }">
        {{ item.address | formatAddress }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small color="secondary">
              <v-icon
                small
                @click="openItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
          <span>Détails</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small color="info">
              <v-icon
                small
                @click="$refs.editForm.open(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Modifier</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon small color="error">
              <v-icon
                small
                @click="$refs.removeForm.open(item)"
                v-bind="attrs"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          <span>Supprimer</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <EditCompany ref="editForm" @finished="fetchData" />
    <RemoveItem
      resource="companies"
      title="cette entreprise"
      ref="removeForm"
      @finished="fetchData"
    />
  </v-container>
</template>

<script>
import EditCompany from "@/components/Companies/EditCompany.vue"
import RemoveItem from "@/components/RemoveItem.vue"

import { debounce } from 'lodash'

export default {
  components: { EditCompany, RemoveItem },
  data () {
    return {
      enableSearch: false,
      query: '',
      tableLoading: false,
      companies: [],
      options: {

      },
      totalCount: 0,
      headers: [
        ...(this.$store.state.debugMode ? [{
          text: 'Id',
          value: 'id'
        }] : []),
        {
          text: "Nom de l'entreprise",
          value: 'name'
        },
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Adresse',
          value: 'address'
        },
        {
          text: 'Créé le',
          value: 'createdAt'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  watch: {
    query: debounce(function(_) {
      this.fetchData()
    }, 500),
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
  },
  mounted () {
  },
  methods: {
    fetchData () {
      this.tableLoading = true
      let additionalParams = {}
      if (this.query.length > 0) {
        [
          'name',
          'address.firstLine',
          'address.secondLine',
          'address.postalCode',
          'address.city'
        ].forEach(key => {
          additionalParams[key] = this.query
        })
      }
      this.$store.dispatch(
        'companies/fetchAll', { ...this.options, additionalParams }
      ).then(res => {
        this.tableLoading = false
        this.companies = res.member
        this.totalCount = res.totalItems
      })
    },
    onClickedRow (_, { item }) {
      this.openItem(item)
    },
    openItem(item) {
      this.$router.push({ name: 'CompanySites', params: { id: item.id } })
    },
    toggleSearch () {
      this.enableSearch = !this.enableSearch
      if (this.enableSearch) {
        this.$nextTick(() => {
          this.$refs.searchField.$el.focus()
        })
      } else {
        this.query = ''
      }
    }
  }
}
</script>
